.modal-dialog {
  width: 600px;
}

.modal.fade {
  display: flex!important;
  justify-content: center;
  align-items: center;
  .modal-dialog {
    margin: 0;
  }
}

.form-group {
  margin-bottom: 1rem !important;
}

.row {
  -ms-flex-wrap: nowrap; //Fixes weirdness in Safari
  flex-wrap: nowrap; //Fixes weirdness in Safari
}

body, body.mobile-on {
  min-width: 0;
}

// This is actually overriding user agent stylesheet - but it's something navex-styles should already be doing
// TODO: Request this be added to navex-styles or submit a PR
table {
  font-size: 1rem;
}

#loadingpanel {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 5000;
}