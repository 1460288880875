/* This css is shared across all AppShell microfrontends */
$fa-font-path: '/assets/fonts';
@import './style-guide';
@import './bootstrap.overrides';
@import './navex-styles.overrides';

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.page-section-title {
  font-size: 1.333rem;
  text-transform: uppercase;
  width: 100%;
  margin-bottom: 0.667rem;
  margin-top: 0;
}

.critical-error{
  padding: 15px;
  color: white;
  background-color: #e3094c;

  a {
    color: white;
    text-decoration: underline;
  }

  a:hover {
    opacity: .8;
  }
}