.btn + .btn {
	margin-left:10px;
}


//The style Guide only covers a elements but reactstrap renders these as buttons
.dropdown-item {
  cursor: pointer;
}

.btn[disabled] {
  cursor: not-allowed;
}

.container {
  padding: 0;
}

.row {
  margin-bottom: 1rem;
}

.mobile-on {
  .btn:hover {
    // Added this in response to Pivotal #159644179
    // This gets rid of the semi-opaque hover effect on iPhone which doesn't look as cool, but does resolve the bug
    // Also created Pivotal #160077025 to maybe someday add the effect back in (without creating the bug again)
    opacity: 1
  }
}
